.ClickerCoin {
    width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;
    border-radius: 50%;
    font-size: 72px;
    font-weight: 700;
    text-align: center;
    line-height: 280px;
    background-size: cover;
    margin: 0 auto;
    transition: transform .1s ease-out;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    touch-action: none;
    user-zoom: fixed;
    transform-origin: center;
    will-change: transform;
    position: relative;
    z-index: 1;
}

.ClickerCoinAmount {
    position: absolute;
    top:0;
    left: 0;
    z-index: 90000;
    will-change: transform;
}

.ClickerCoinTimer {
    position: absolute;
    width: 240px;
    height: 240px;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    /* opacity: 0.3; */
}

.ClickerCoinAmountDiv {
    top: 0;
    left: 0;
    position: absolute;
    font-size: 42px;
    line-height: 48px;
    font-weight: 700;
    color: #ffffff;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
    will-change: transform;
}

.ClickerCoinAmountDivGroupValue {
    position: absolute;
    top: 0%;
    left: 100%;
    font-size: 12px;
    line-height: 16px;
    padding: 0 4px;
    background-color: rgba(255,255,255);
    color: #000000;
    border-radius: 8px;
    opacity: 0.6;
}

.ClickerCoinDot {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    /* width: 64px;
    height: 64px; */
    display: block;
    border-radius: 50%;
    background: rgba(255, 198, 25, 1);
    transform: translate(-50%, -50%);
    transition: all 0.3s;
}

.ClickerCoinDot::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    right: 0;
    border-radius: 50%;
    transform: scale(1);
    background: rgba(255, 198, 25, 1);
    box-shadow: 0 0 0 0 rgba(255, 198, 25, 1);
    animation: pulse-yellow 2s infinite;
}

.ClickerCoinDot::after {
    position: absolute;
    content: attr(data-text);
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: block;
    font-size: inherit;
    line-height: 50px;
    letter-spacing: 1px;
    color: #000000;
}

@keyframes pulse-yellow {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 198, 25, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba(255, 198, 25, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 198, 25, 0);
    }
}

.ClickerCoinRing {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,0.2);
}

.ClickerCoinRing100 {
    width: 100px;
    height: 100px;
    border-color: rgba(255,255,255,0.2);
}

.ClickerCoinRing150 {
    width: 150px;
    height: 150px;
    border-color: rgba(255,255,255,0.15);
}

.ClickerCoinRing180 {
    width: 180px;
    height: 180px;
    border-color: rgba(255,255,255,0.1);
}

.ClickerCoinRing200 {
    width: 200px;
    height: 200px;
    border-color: rgba(255,255,255,0.08);
} 

@media only screen and (max-width : 320px) {
    .ClickerCoin {
        height: 260px;
        min-height: 260px;
        width: 260px;
        min-width: 260px;
    }

    .ClickerCoinAmountDiv {
        font-size: 36px;
        line-height: 40px;
    }
}