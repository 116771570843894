@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400..700&display=swap');

:root {
  --dot-accent: #ffc619;
}

.App {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  gap: 32px;
}