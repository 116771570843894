.Socials {
    display: flex;
    gap: 32px;
}

.SocialsButton {
    text-decoration: none;
    color: #ffffff;
}

.SocialsButton:hover {
    opacity: 0.8;
}