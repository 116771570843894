.FooterButton {
    display: block;
    font-size: 24px;
    line-height: 40px;
    padding: 12px 32px;
    font-weight: bold;
    border-radius: 36px;
    z-index: 999;
    background: linear-gradient(101deg, #ffa800 25.94%, #f47e3c 94.32%);
    color: #ffffff;
    text-decoration: none;
}

.FooterButton:hover {
    opacity: 0.8;
}